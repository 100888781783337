import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "days", "hours", "minutes", "seconds" ]
  static values = { start: String }
	

  connect() {
    this.target=this.startValue

    this.animate()
  }

  animate () {
    let startTimestamp = null

    const step = timestamp => {
      if (!startTimestamp) startTimestamp = timestamp

      var interval=this.target-Date.now()/1000
      var days = Math.floor(interval/86400)
      var hours = Math.floor((interval-days*86400)/3600)
      var mins = Math.floor((interval-days*86400-hours*3600)/60)
      var secs = Math.floor((interval-days*86400-hours*3600-mins*60))

      if (days>=0) {
        this.daysTarget.textContent = days
        this.hoursTarget.textContent = String(hours).padStart(2, '0')
        this.minutesTarget.textContent = String(mins).padStart(2, '0')
        this.secondsTarget.textContent = String(secs).padStart(2,'0')
        window.requestAnimationFrame(step)
      } else {
        this.daysTarget.textContent = "0"
        this.hoursTarget.textContent = "00"
        this.minutesTarget.textContent = "00"
        this.secondsTarget.textContent = "00"
      }
    }

    window.requestAnimationFrame(step)
  }
}
