import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { id: Number }


  connect() {
    var element = document.getElementById('bid_amount');
    var maskOptions = {
      mask: Number,  // enable number mask
      // other options are optional with defaults below
      scale: 0,  // digits after point, 0 for integers
      signed: false,  // disallow negative
      thousandsSeparator: ',',  // any single char
      radix: ',',  // fractional delimiter
      mapToRadix: ['.']  // symbols to process as radix

      // additional number interval options (e.g.)
      //min: -10000,
      //max: 10000
    }
    var mask = IMask(element, maskOptions);
  }

  disconnect() {
  }
}
