import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "days", "hours", "minutes" ]
  static values = { start: String }
	

  connect() {
    this.target=this.startValue

    this.animate()
  }

  animate () {
    let startTimestamp = null

    const step = timestamp => {
      if (!startTimestamp) startTimestamp = timestamp

      var interval=this.target-Date.now()/1000
      var days = Math.floor(interval/86400)
      var hours = Math.floor((interval-days*86400)/3600)
      var mins = Math.floor((interval-days*86400-hours*3600)/60)

      this.daysTarget.textContent = days
      this.hoursTarget.textContent = hours
      this.minutesTarget.textContent = mins

      window.requestAnimationFrame(step)
    }

    window.requestAnimationFrame(step)
  }
}
