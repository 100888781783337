// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ ]

  connect() {
    var form = document.getElementById('payment-form');
    var stripe = Stripe(form.dataset.pkey);
    var elements = stripe.elements();
    var style = {
      base: {
      }
    };

    var card = elements.create("card", { style: style, disabled: true });
    card.mount("#card-element");
    document.getElementById('submit').disabled=true;
     
    document.getElementById('confirm').addEventListener('change', function (){
        card.update({ disabled: false });
        document.getElementById('submit').disabled=false;
        document.getElementById('confirm').disabled=true;
    });


    card.on('change', ({error}) => {
      let displayError = document.getElementById('card-errors');
      if (error) {
        displayError.textContent = error.message;
      } else {
        displayError.textContent = '';
      }
    });


    form.addEventListener('submit', function(ev) {
      ev.preventDefault();
      stripe.confirmCardPayment(form.dataset.secret, {
        payment_method: {
        card: card
	}
      }).then(function(result) {
	if (result.error) {
	  // Show error to your customer (e.g., insufficient funds)
	  alert(result.error.message);
	} else {
	  // The payment has been processed!
	  if (result.paymentIntent.status === 'succeeded') {
	    const event = new Event('payment_success');
	    window.dispatchEvent(event);
	  }
	}
      });
    });
  }
}
